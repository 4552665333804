// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import axios from 'axios'; // Import axios for data fetching
import { getData } from './dataLoader';


const firebaseConfig = {
  apiKey: "AIzaSyDybF8bCPAP1vlSQTuvh3bUZurNctOc87E",
  authDomain: "data-window.firebaseapp.com",
  projectId: "data-window",
  storageBucket: "data-window.appspot.com",
  messagingSenderId: "529518459649",
  appId: "1:529518459649:web:7fdb59dec10e3cae3ff900",
  measurementId: "G-1JCVLDM038"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Firestore and get a reference to the service
export const db = getFirestore(app);

// Function to fetch permissible sites for a user
export const fetchPermissibleSites = async (userEmail) => {
  console.log(`Fetching permissible sites for user: ${userEmail}`);

  const sitePermissionsRef = collection(db, "sitePermissions");
  const sitesRef = collection(db, "sites");

  try {
    // Fetch permissible site IDs from sitePermissions
    const sitePermissionsSnapshot = await getDocs(sitePermissionsRef);
    const permissibleSiteIds = [];

    sitePermissionsSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data && data.users && Array.isArray(data.users) && data.users.includes(userEmail)) {
        permissibleSiteIds.push(doc.id);
      }
    });

    console.log("Permissible site IDs:", permissibleSiteIds);

    // Fetch site details for permissible site IDs
    const siteDetails = [];
    const sitesSnapshot = await getDocs(sitesRef);

    sitesSnapshot.forEach((doc) => {
      if (permissibleSiteIds.includes(doc.id)) {
        const siteData = doc.data();
        siteDetails.push({
          name: doc.id, // Site name (document ID)
          thumbnailUrl: siteData.thumbnailUrl || "", // Directly access thumbnailUrl
        });
      }
    });

    console.log("Fetched site details with thumbnails:", siteDetails);
    return siteDetails;
  } catch (error) {
    console.error("Error fetching permissible sites:", error);
    throw error;
  }
};


export const getDataForAllSites = async (sites) => {
  const allSiteData = [];

  for (const site of sites) {
    const encodedSiteName = encodeURIComponent(site);
    const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodedSiteName}`;

    try {
      const siteConfigUrl = `${baseUrl}/siteConfig.json`;
      const siteConfigResponse = await axios.get(siteConfigUrl);
      const siteConfig = siteConfigResponse.data;

      // Fetch data for the site and add to allSiteData
      const siteData = await getData(
        baseUrl,
        siteConfig.layers,
        siteConfig.IB,
        siteConfig.levels
      );

      allSiteData.push({
        siteName: site,
        siteData
      });
    } catch (error) {
      console.error(`Error fetching data for site ${site}:`, error);
    }
  }

  return allSiteData;
};