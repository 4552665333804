import React, { useEffect, useState } from 'react';
import { fetchPermissibleSites, auth } from '../utils/firebase';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Button, Paper, List, ListItem, ListItemText } from '@mui/material';
import { query, collection, where, getDocs } from 'firebase/firestore'; 
import { db } from '../utils/firebase'; 
import { Person, Logout } from '@mui/icons-material';
import './admin.css';
import axios from 'axios';

function Admin({ currentUser }) {
  const [sites, setSites] = useState([]);
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [siteData, setSiteData] = useState(null);
  const [previewImage, setPreviewImage] = useState(''); // State for preview image
  const [fadingOut, setFadingOut] = useState(false); // State for fade-out effect
  const [containerHeight, setContainerHeight] = useState('auto');

  useEffect(() => {
    const preloadImages = () => {
      sites.forEach((site) => {
        if (site.thumbnailUrl) {
          const img = new Image();
          img.src = site.thumbnailUrl; // Start preloading the image
        }
      });
    };

    if (sites.length > 0) {
      preloadImages();
    }
  }, [sites]);

  useEffect(() => {
    const itemHeight = 60; 
    const maxItems = 5;
    const adjustedHeight = Math.min(sites.length, maxItems) * itemHeight + 20;

    setContainerHeight(`${adjustedHeight}px`);
  }, [sites]);

  useEffect(() => {
    if (currentUser) {
      fetchPermissibleSites(currentUser.email)
        .then((fetchedSites) => {
          setSites(fetchedSites);
        })
        .catch((error) => console.error('Error fetching sites:', error));

      const fetchUserName = async () => {
        try {
          const q = query(collection(db, 'userAccounts'), where('email', '==', currentUser.email));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data(); 
            setUserName(userData.name); 
          } else {
            console.error('No user document found!');
          }
        } catch (error) {
          console.error('Error fetching user document:', error);
        }
      };

      fetchUserName(); 
    }
  }, [currentUser]);

  const handleSiteClick = async (site) => {
    setLoading(true);
    try {
      const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodeURIComponent(site)}`;
      const siteConfigUrl = `${baseUrl}/siteConfig.json`;

      const siteConfigResponse = await axios.get(siteConfigUrl);
      const siteConfig = siteConfigResponse.data;

      const dateFolders = siteConfig.dateFolders || [];
      
      if (dateFolders.length === 0) {
        console.error(`No date folders found for site ${site}`);
        return;
      }

      dateFolders.sort((a, b) => b.date.localeCompare(a.date));
      const latestDateFolder = dateFolders[0]; 
      console.log('site name in admin.js', site);
      navigate(`/site-dashboard/${encodeURIComponent(site)}`, {
        state: { siteName: site, latestDate: latestDateFolder.date }
      });
    } catch (error) {
      console.error('Error fetching siteConfig:', error);
    }
    setLoading(false);
  };

  const handleSignOut = () => {
    auth.signOut()
      .then(() => {
        console.log('User signed out');
        navigate('/login'); 
      })
      .catch((error) => {
        console.error('Sign out error', error);
      });
  };

  return (
    <Box className="admin-container">
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1, // Behind all other content
          backgroundImage: previewImage ? `url(${previewImage})` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: fadingOut ? 0 : (previewImage ? 0.9 : 0), // Smooth fade-in and fade-out
          transition: 'opacity 0.4s ease'
        }}
        onTransitionEnd={() => {
          if (fadingOut) setPreviewImage(''); // Clear image after fade-out completes
        }}
      ></div>

      <Typography variant="h4" className="welcome-message" gutterBottom>
        Welcome, {userName || ''}
      </Typography>

      <Paper 
        className="site-list-container"
        style={{ height: containerHeight, overflowY: sites.length > 5 ? 'auto' : 'hidden' }}
      >
        <Typography variant="h6" className="site-list-title" gutterBottom>
          Your Sites
        </Typography>
        
        <List className="site-list">
          {sites.length > 0 ? (
            sites.map((site) => (
              <ListItem
                key={site.name}
                button
                onClick={() => handleSiteClick(site.name)}
                className="site-list-item"
                onMouseEnter={() => {
                  setFadingOut(false); // Reset fade-out
                  setPreviewImage(site.thumbnailUrl || ''); // Set preview image
                }}
                onMouseLeave={() => {
                  setFadingOut(true); // Trigger fade-out
                }}
              >
                <ListItemText primary={site.name} />
              </ListItem>
            ))
          ) : (
            <Typography variant="body2" className="no-sites-message">
              No sites available
            </Typography>
          )}
        </List>
      </Paper>

      <div className="admin-button-container">
        <Button
          variant="contained"
          onClick={() => navigate('/account-info')}
          className="account-info-button"
        >
          <Person /> My Account
        </Button>

        <Button
          variant="contained"
          onClick={handleSignOut}
          className="sign-out-button"
          startIcon={<Logout />}
        >
          Sign Out
        </Button>
      </div>
    </Box>
  );
}

export default Admin;
