import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faExclamationTriangle, faChartLine, faMap } from '@fortawesome/free-solid-svg-icons';
import './TopNavBar.css';

const TopNavBar = ({siteName, selectedDate}) => {

  const navigate = useNavigate();

  return (
    <nav className="top-nav-bar">
      <ul>
        <li>
          {/* Pass the siteName via state */}
          <button onClick={() => navigate(`/site-dashboard/${encodeURIComponent(siteName)}`, { state: { siteName, selectedDate } })}>
            <FontAwesomeIcon icon={faHome} /> Overview
          </button>
        </li>
        <li>
          <button onClick={() => navigate('/fault-data', { state: { siteName, selectedDate } })}>
            <FontAwesomeIcon icon={faExclamationTriangle} /> Fault Data
          </button>
        </li>
        <li>
          <button onClick={() => navigate('/revenue-analysis', { state: { siteName, selectedDate } })}>
            <FontAwesomeIcon icon={faChartLine} /> Revenue Analysis
          </button>
        </li>
        <li>
          <button onClick={() => navigate(`/home/${encodeURIComponent(siteName)}`, { state: { siteName, selectedDate} })}>
            <FontAwesomeIcon icon={faMap} /> Map Overview
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default TopNavBar;
