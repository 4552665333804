import React from 'react';
import './LoadingScreen.css';

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="plane-animation">
        {/* Dots for trailing effect */}
        {[...Array(20)].map((_, index) => (
          <div key={index} className={`dot dot-${index + 1}`}></div>
        ))}
        <img src="/planeLoading.png" alt="Plane" className="plane" />
        <img src="/flashLoading.png" alt="Flash" className="flash" />
        <p><strong>Loading, please wait...</strong></p>
      </div>
    </div>
  );
};

export default LoadingScreen;
