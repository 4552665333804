import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FaUserCircle, FaEdit } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import { db, auth } from '../utils/firebase'; 
import { doc, getDoc, updateDoc, collection, query, where, getDocs  } from "firebase/firestore";
import './HeaderBar.css';

const HeaderBar = ({ siteName }) => {
    const [panelProd, setPanelProd] = useState(400); // Default in watts
    const [energyRate, setEnergyRate] = useState(0.42);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [logoUrl, setLogoUrl] = useState("/HZVTLogo.png");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserLogo = async () => {
            const user = auth.currentUser;

            if (!user) {
                console.warn("No user is logged in. Using default logo.");
                setLogoUrl("/HZVTLogo.png");
                return;
            }

            console.log("Fetching logo for email:", user.email);

            const q = query(
                collection(db, "userAccounts"),
                where("email", "==", user.email)
            );

            try {
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    querySnapshot.forEach((doc) => {
                        const userData = doc.data();
                        const customLogo = userData.logo || null;
                        console.log("Retrieved custom logo:", customLogo);

                        setLogoUrl(customLogo || "/HZVTLogo.png");
                    });
                } else {
                    console.warn("No user data found for email:", user.email);
                }
            } catch (error) {
                console.error("Error fetching user logo:", error);
                setLogoUrl("/HZVTLogo.png"); // Fallback to default logo on error
            }
        };

        fetchUserLogo();
    }, []);

    useEffect(() => {
        const fetchSiteData = async () => {
            if (!siteName) return;

            const siteDocRef = doc(db, 'sites', siteName);
            try {
                const docSnapshot = await getDoc(siteDocRef);
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    setPanelProd((data.customFields?.panelProd || 0.5) * 1000);
                    setEnergyRate(data.customFields?.kwValue || 0.12);
                } else {
                    console.error(`No Document found for site: ${siteName}`);
                }
            } catch (err) {
                console.error('Error fetching site data:', err);
                setError('Could not load site data');
            } finally {
                setLoading(false);
            }
        };

        fetchSiteData();
    }, [siteName]);

    const saveSiteData = async () => {
        if (!siteName) return;

        setSaveLoading(true);
        const siteDocRef = doc(db, 'sites', siteName);

        try {
            await updateDoc(siteDocRef, {
                customFields: {
                    panelProd: panelProd / 1000, // Convert to kW for storage
                    kwValue: energyRate,
                },
            });
            setSuccessMessage('Settings saved successfully!');
            setTimeout(() => setSuccessMessage(''), 3000); // Clear success message after 3 seconds
            setIsEditing(false);
        } catch (err) {
            console.error('Error saving site data:', err);
            alert('Failed to save settings.');
        } finally {
            setSaveLoading(false);
        }
    };

    const handleInputChange = (e, setValue) => {
        const input = e.target.value;
    
        // Allow only numbers, decimal points, or empty strings
        if (/^(\d+(\.\d*)?)?$/.test(input)) {
            setValue(input);
        }
    };

    const validateInput = (value, setValue, defaultValue) => {
        // Check if the value is a valid number
        if (!value || isNaN(value)) {
            setValue(defaultValue); // Reset to default if invalid
        } else {
            setValue(parseFloat(value.startsWith('.') ? `0${value}` : value).toFixed(2));
        }
    };

    return (
        <div className="header-bar">
            <div className="logo-section" onClick={() => navigate('/')}>
                <img src={logoUrl} alt="Company Logo" className="site-logo hover-effect" />
            </div>

            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}

            <div className="settings-section">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <CSSTransition
                        in={!isEditing}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <div className="display-mode">
                            <p>Per Panel Production: <strong>{panelProd} Watts</strong></p>
                            <p>Energy Rate: <strong>${energyRate} / kWh</strong></p>
                            <button className="edit-button" onClick={() => setIsEditing(true)}>
                                <FaEdit /> Edit
                            </button>
                        </div>
                    </CSSTransition>
                )}

                <CSSTransition in={isEditing} timeout={300} classNames="fade" unmountOnExit>
                    <div className="edit-mode">
                        <div className = "edit-row">
                            <div className = "edit-buttons">
                                <button onClick={saveSiteData} disabled={saveLoading}>
                                    {saveLoading ? <span className="spinner-icon" /> : 'Save'}
                                </button>
                                <button onClick={() => setIsEditing(false)}>Cancel</button>
                            </div>
                            <div className = "input-group">
                                <div className="input-field">
                                    <label>Per Panel Production (Watts):</label>
                                    <input
                                        type="text"
                                        value={panelProd}
                                        onChange={(e) => handleInputChange(e, setPanelProd)}
                                        onBlur={() => validateInput(panelProd, setPanelProd, 0.5)} // Default to 0.5 if invalid
                                        placeholder="Watts"
                                    />
                                </div>
                                <div className="input-field">
                                    <label>Energy Rate ($/kWh):</label>
                                    <input
                                        type="text"
                                        value={energyRate}
                                        onChange={(e) => handleInputChange(e, setEnergyRate)}
                                        onBlur={() => validateInput(energyRate, setEnergyRate, 0.12)} // Default to 0.12 if invalid
                                        placeholder="USD"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </div>

            <div className="account-icon-section">
                <button
                    className="account-info-button"
                    onClick={() => navigate('/account-info')}
                >
                    <FaUserCircle size={32} className="account-icon" />
                    <span>Account</span>
                </button>
            </div>
        </div>
    );
};

export default HeaderBar;