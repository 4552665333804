import axios from 'axios';

// Function to fetch site data
export const getData = async (baseUrl, layersConfig, IBConfig, levelsConfig) => {
  console.log('getData function called with URL:', baseUrl);

  try {
    // Log configurations for debugging
    console.log('Layers Config:', layersConfig);
    console.log('IB Config:', IBConfig);
    console.log('Levels Config:', levelsConfig);

    // Fetch site data
    console.log('Requesting site data from:', `${baseUrl}/siteData.json`);
    const siteDataResponse = await axios.get(`${baseUrl}/siteData.json`);
    const siteData = siteDataResponse.data;
    console.log('Site data response:', siteData);

    // Initialize necessary variables
    const center = { lat: siteData.lat, lng: siteData.lng };
    const panelCount = siteData.panelCount || 0;
    const rectangles = siteData.rectangles || [];
    const effectedPanelsByRectangleId = {};
    const imagesByRectangleId = {};
    const imagesByFaultId = {};
    const IBImages = {};
    const annotations = [];
    const groupedDataByLevel = {};
    const categories = [];
    const panelProd = siteData.panelProd || 0.5; // default value if not set
    const energyRate = siteData.kwValue || 0.12; // default value if not set

    let totalPanelsEffected = 0;
    let totalFaultsFound = 0;

    

    // Initialize rectangle-related data
    rectangles.forEach(rect => {
      effectedPanelsByRectangleId[rect.id] = 0;
      imagesByRectangleId[rect.id] = [];
    });

    // Fetch IB images (if IBConfig is valid)
    if (IBConfig && IBConfig.folderName && IBConfig.imageFormat) {
      const ibFolderUrl = `${baseUrl}/${IBConfig.folderName}`;
      for (const rect of rectangles) {
        const ibImagePath = `${ibFolderUrl}/${rect.id}${IBConfig.imageFormat}`;
        try {
          await axios.head(ibImagePath);
          IBImages[rect.id] = ibImagePath;
        } catch (err) {
          console.error(`Error fetching IB image for ${rect.id}:`, err);
        }
      }
    }

    // Fetch levels and categories
    if (levelsConfig) {
      for (const level of levelsConfig) {
        const levelName = encodeURIComponent(level.levelName);
        console.log(`Processing level: ${levelName}`);

        if (!groupedDataByLevel[levelName]) {
          groupedDataByLevel[levelName] = {};
        }

        const levelCategories = [];
        for (const category of level.categories) {
          const categoryName = encodeURIComponent(category.categoryName);
          console.log(`Processing category: ${categoryName}`);

          if (!groupedDataByLevel[levelName][categoryName]) {
            groupedDataByLevel[levelName][categoryName] = 0;
          }

          if (category.coordinatesFile) {
            const coordinatesFileUrl = `${baseUrl}/${levelName}/${categoryName}/${category.coordinatesFile}`;
            console.log(`Requesting coordinates data from: ${coordinatesFileUrl}`);

            try {
              const coordinatesResponse = await axios.get(coordinatesFileUrl);
              const coordinates = coordinatesResponse.data;

              console.log("Fetched coordinates data:", coordinates);

              coordinates.forEach(coord => {
                const ibMatch = coord.failure_location.match(/^IB\d+/);
                if (ibMatch && effectedPanelsByRectangleId[ibMatch[0]] !== undefined) {
                  effectedPanelsByRectangleId[ibMatch[0]] += coord.effected_panels;

                  const imagePath = `${baseUrl}/${levelName}/${categoryName}/${category.imageFolder}/${coord.image}`;
                  imagesByRectangleId[ibMatch[0]].push({
                    imagePath,
                    failure_location: coord.failure_location,
                    lat: coord.lat,
                    lng: coord.lng,
                    category: coord.category,
                    effected_panels: coord.effected_panels || 0,
                    level: coord.level
                  });

                  if(!imagesByFaultId[coord.failure_location]){
                    imagesByFaultId[coord.failure_location] = [];
                  }
                  imagesByFaultId[coord.failure_location].push({
                    imagePath,
                    failure_location: coord.failure_location,
                    lat: coord.lat,
                    lng: coord.lng,
                    category: coord.category,
                    effected_panels: coord.effected_panels || 0,
                    level: coord.level
                  });

                  totalPanelsEffected += coord.effected_panels;
                  totalFaultsFound += 1;

                  groupedDataByLevel[levelName][categoryName] += coord.effected_panels;
                }

                annotations.push({
                  ...coord,
                  category: category.categoryName,
                  onClick: () => alert(`Annotation data at (${coord.lat}, ${coord.lng})`),
                });
              });
            } catch (err) {
              console.error(`Error fetching coordinates for category ${category.categoryName}:`, err);
            }
          }

          // Add each category and its associated level to the levelCategories array
          levelCategories.push({
            categoryName: category.categoryName,
            levelName: level.levelName,
          });
        }

        // Add the processed categories of the level to the main categories array
        categories.push({
          levelName: level.levelName,
          categories: levelCategories,
        });
      }
    }

    // Fetch layer data
    const layerCoordinatesUrl = `${baseUrl}/${layersConfig.folderName}/${layersConfig.coordinates}`;
    console.log('Requesting layer data from:', layerCoordinatesUrl);

    let layerData, mainOverlay, otherOverlays;
    try {
      const layerResponse = await axios.get(layerCoordinatesUrl);
      layerData = layerResponse.data;
      mainOverlay = layerData.overlays.find(overlay => overlay.isMain);
      otherOverlays = layerData.overlays.filter(overlay => !overlay.isMain);

      if (mainOverlay) {
        mainOverlay.imageUrl = `${baseUrl}/${layersConfig.folderName}/${mainOverlay.imageUrl}`;
        mainOverlay.bounds = layerData.bounds; // Use global bounds for main overlay
      }

      otherOverlays.forEach(overlay => {
        overlay.imageUrl = `${baseUrl}/${layersConfig.folderName}/${overlay.imageUrl}`;
        overlay.bounds = overlay.bounds || layerData.bounds; // Use global bounds if specific bounds are not provided
      });
    } catch (err) {
      console.error('Error fetching layer data:', err);
      throw err;
    }

    // Calculate statistics
    const potentialMaxKWGeneration = panelCount * 0.5; // 500W per panel
    const lostKWDueToFaults = totalPanelsEffected * 0.5; // 500W per panel
    const percentLost = ((lostKWDueToFaults / potentialMaxKWGeneration) * 100).toFixed(2);
    const missedOutRevenue = (lostKWDueToFaults * 0.12 * 365).toFixed(2); // Assuming $0.12 per kWh per day

    const statistics = {
      totalPanelCount: panelCount,
      totalFaultsFound,
      totalPanelsEffected,
      potentialMaxKWGeneration,
      lostKWDueToFaults,
      percentLost: parseFloat(percentLost),
      missedOutRevenue: parseFloat(missedOutRevenue),
    };

    console.log('Final statistics:', statistics);

    return {
      center,
      rectangles,
      panelCount,
      annotations,
      categories, // Now categories are populated
      statistics,
      layerData,
      mainOverlay,
      otherOverlays,
      effectedPanelsByRectangleId,
      imagesByRectangleId,
      imagesByFaultId,
      IBImages,
      groupedDataByLevel,
      panelProd,
      energyRate
    };
  } catch (error) {
    console.error('Error loading data:', error);
    throw error;
  }
};
