import React, { useState, useEffect } from 'react';
import {motion} from 'framer-motion';
import TopNavBar from './TopNavBar';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingScreen from './loadingScreen';
import 'animate.css';
import './FaultData.css';
import { FaCheckCircle, FaTimesCircle, FaPlusCircle, FaMinusCircle, FaDownload, FaFilter } from 'react-icons/fa'; 
import { getData } from '../utils/dataLoader';
import HeaderBar from './HeaderBar';

import { throttle } from 'lodash';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from "react-dom";
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const FaultData = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { siteName, selectedDate } = location.state || {};

    const [faultData, setFaultData] = useState([]);
    const [columns, setColumns] = useState(['Location', 'Level', 'Category', 'Affected Panels']);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false); 
    const [availableAttributes, setAvailableAttributes] = useState([]); 
    const [filterMenuVisible, setFilterMenuVisible] = useState(false); 

    const [selectedLevels, setSelectedLevels] = useState([]); 
    const [availableLevels, setAvailableLevels] = useState([]); 
    const [selectedCategories, setSelectedCategories] = useState([]); 
    const [availableCategories, setAvailableCategories] = useState([]); 

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipContent, setTooltipContent] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });

    const [isCustomMode, setIsCustomMode] = useState(false);
    const [customTableRows, setCustomTableRows] = useState([]);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const [exportMenuVisible, setExportMenuVisible] = useState(false);
    const [exportDropdownPos, setExportDropdownPos] = useState({ top: 0, left: 0});
    

    const DraggableHeader = ({ column, index, moveColumn }) => {
        const [, dragRef] = useDrag({
            type: 'COLUMN',
            item: { index },
        });
    
        const [, dropRef] = useDrop({
            accept: 'COLUMN',
            hover: (draggedItem) => {
                if (draggedItem.index !== index) {
                    moveColumn(draggedItem.index, index);
                    draggedItem.index = index;
                }
            },
        });
    
        return (
            <th ref={(node) => dragRef(dropRef(node))}>
                <div className="header-content">
                    <span className="drag-handle">☰</span>
                    <span>{column}</span>
                    {column !== 'Location' && (
                        <FaMinusCircle
                            size={14}
                            className="remove-column-icon"
                            onClick={() => removeColumn(column)}
                        />
                    )}
                </div>
            </th>
        );
    };
    
    const encodeForCSV = value => {
        if (value === null || value === undefined) {
            return '""';
        }
        const stringValue = String(value); 
        if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
            return `"${stringValue.replace(/"/g, '""')}"`;
        }
        return stringValue;
    };

    const handleRowHover = (event, faultLocation) => {
        const fault = data.find(row => row.Location === faultLocation);
        console.log("Fault object on hover: " , fault);

        if (fault) {
            
            const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodeURIComponent(siteName)}/${selectedDate}/${fault.Level}/${fault.Category}/images`;
            const faultImageFileName = fault.image ? encodeURIComponent(fault.image) : null; 
            const faultImageUrl = faultImageFileName ? `${baseUrl}/${faultImageFileName}` : null;

            console.log("Image URL from 'handle row hover':  " , faultImageUrl);

            if (!faultImageUrl) {
                console.error(`Image not found for fault: ${faultLocation}`);
            }
    
            setTooltipContent(faultImageUrl || '/path-to-placeholder-image.png'); // Use placeholder if faultImageUrl is undefined
            setTooltipPosition({ x: event.clientX + 10, y: event.clientY + 10 });
            setTooltipVisible(true);
        }
    };
    
    const handleMouseMoveThrottled = throttle((event) => {
        const tooltipWidth = 150; 
        const tooltipHeight = 150; 
        let x = event.clientX + window.scrollX + 10;
        let y = event.clientY + window.scrollY + 10;

        if (x + tooltipWidth > window.innerWidth + window.scrollX) {
            x = event.clientX + window.scrollX - tooltipWidth - 10;
        }
        if (y + tooltipHeight > window.innerHeight + window.scrollY) {
            y = event.clientY + window.scrollY - tooltipHeight - 10;
        }
        setTooltipPosition((prev) => {
            if (prev.x !== x || prev.y !== y) {
                return { x, y }; 
            }
            return prev;
        });
    }, 180);

    const handleMouseLeave = () => {
        setTooltipVisible(false);
        setTooltipContent(null);
    };

   
    useEffect(() => {
        if (!siteName || !selectedDate) {
            navigate('/'); 
        }
        console.log('Selected date in FaultData:', selectedDate); 
    }, [siteName, selectedDate, navigate]);

    // Fetch configuration and data
    useEffect(() => {
        const fetchConfigAndData = async () => {
            const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodeURIComponent(siteName)}`;
            const siteConfigUrl = `${baseUrl}/siteConfig.json`;

            try {
                const response = await fetch(siteConfigUrl);
                const configData = await response.json();
                const dateConfig = configData.dateFolders.find(
                    (folder) => folder.date === selectedDate
                );

                if (!dateConfig) {
                    throw new Error(`No configuration found for the selected date: ${selectedDate}`);
                }

                const layersConfig = dateConfig.layers;
                const IBConfig = dateConfig.IB;
                const levelsConfig = dateConfig.levels;

                const selectedDateFolder = encodeURIComponent(selectedDate);
                const fetchedData = await getData(`${baseUrl}/${selectedDateFolder}`, layersConfig, IBConfig, levelsConfig);

                const rows = fetchedData.annotations.map((annotation) => {
                    console.log("Annotation data: ", annotation);
                    return {
                        Location: annotation.failure_location,
                        Level: annotation.level,
                        Category: annotation.category,
                        'Affected Panels': annotation.effected_panels || 0,
                        image: annotation.image || null
                    }
                });


                const uniqueLevels = [...new Set(rows.map(row => row.Level))];
                const uniqueCategories = [...new Set(rows.map(row => row.Category))];
                
                setAvailableLevels(uniqueLevels);
                setAvailableCategories(uniqueCategories);

                setSelectedLevels(uniqueLevels);
                setSelectedCategories(uniqueCategories);
                
                setData(rows);
                setFilteredData(rows); 
                setLoading(false);
            } catch (error) {
                console.error('Error fetching fault data:', error);
                setError('Failed to fetch fault data');
                setLoading(false);
            }
        };

        fetchConfigAndData();
    }, [siteName, selectedDate]);

    const addColumn = (attribute) => {
        if (!columns.includes(attribute)) {
            setColumns([...columns, attribute]);
            setAvailableAttributes(availableAttributes.filter(attr => attr !== attribute)); 
            setShowDropdown(false); 
        }
    };

    const removeColumn = (attribute) => {
        if (attribute !== 'Location') { 
            setColumns(columns.filter(col => col !== attribute));
            setAvailableAttributes([...availableAttributes, attribute]);
        }
    };

    const toggleDropdown = (event) => {
        setShowDropdown(!showDropdown);
        if (!showDropdown) {
            const rect = event.target.getBoundingClientRect();
            setDropdownPosition({
                x: rect.left + window.scrollX,
                y: rect.bottom + window.scrollY
            });
        }
    };

    const toggleExportMenu = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setExportDropdownPos({
            top: rect.bottom + window.scrollY, 
            left: rect.left + window.scrollX,  
        });
        setExportMenuVisible((prev) => !prev); 
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const isInsideButton = event.target.closest('.export-button-section');
            if (!isInsideButton && exportMenuVisible) {
                setExportMenuVisible(false);
            }
        };
    
        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, [exportMenuVisible]);

    const exportToCSV = () => {
        const dataToExport = isCustomMode
            ? customTableRows.filter(row => row.isIncluded)
            : filteredData;
        const csvRows = [];
        csvRows.push(columns.map(encodeForCSV).join(','));

        dataToExport.forEach(row => {
          const rowValues = columns.map(col => encodeForCSV(row[col]));
          csvRows.push(rowValues.join(','));
        });
        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'fault_data_table.csv';
        link.click();
    };

    const exportToPDF = async () => {
        const doc = new jsPDF();
        doc.text("Fault Data Table", 14, 10);
    
        const headers = [...columns, "Fault Image"];

        const filteredRows = isCustomMode
            ? customTableRows.filter(row => row.isIncluded) // Only include rows marked as "included"
            : filteredData;
    
        const rows = await Promise.all(
            filteredRows.map(async (row) => {
                const rowData = [];
                columns.forEach((col) => rowData.push(row[col]));

                const baseUrl = `https://storage.googleapis.com/insite-data-files/files/${encodeURIComponent(siteName)}/${selectedDate}/${row.Level}/${row.Category}/images`;
                const faultImageFileName = row.image ? encodeURIComponent(row.image) : null;
                const imageUrl = faultImageFileName ? `${baseUrl}/${faultImageFileName}` : null;
    
                if (imageUrl) {
                    try {
                        const { base64Data, mimeType } = await fetchImageAsBase64(imageUrl);
                        rowData.push({ base64Data, mimeType }); // Add Base64 image and MIME type
                    } catch (err) {
                        console.error(`Error fetching image for row: ${row.Location}`, err);
                        rowData.push(null);
                    }
                } else {
                    rowData.push(null);  
                }
                return rowData;
            })
        );

        console.log("Rows to add to PDF:", rows);

        const rowsPerPage = 5;
        const chunkedRows = [];
        for (let i = 0; i < rows.length; i += rowsPerPage) {
            chunkedRows.push(rows.slice(i, i + rowsPerPage));
        }

        chunkedRows.forEach((chunk, index) => {
            if (index > 0) {
                doc.addPage();
            }
            doc.autoTable({
                head: [headers],
                body: chunk,
                theme: "grid",
                headStyles: { fillColor: [40, 167, 69], minCellHeight: 20 },
                bodyStyles: { fillColor: [255, 255, 255], cellPadding: 5 },
                alternateRowStyles: { fillColor: [240, 240, 240] },
                margin: { top: 20 },
                startY: 30,
                styles: {
                    cellPadding: 8, // Adjust padding to increase row height
                    minCellHeight: 40, // Set a minimum height for cells
                },
                didDrawCell: (data) => {
                    if (data.column.index === columns.length && data.row.raw) {
                        const imgData = data.row.raw[columns.length];
                        if (imgData && imgData.base64Data && imgData.mimeType) {
                            try {
                                doc.addImage(
                                    imgData.base64Data,
                                    imgData.mimeType.split('/')[1].toUpperCase(),
                                    data.cell.x + 2,
                                    data.cell.y + 2,
                                    30, 
                                    30
                                );
                            } catch (err) {
                                console.error("Error adding image to PDF:", err);
                            }
                        }
                    }
                },
            });
        });
    
        doc.save("tableData.pdf");
    };
    
    const fetchImageAsBase64 = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
    
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
    
            reader.onloadend = () => {
                
                const mimeType = blob.type;
                const base64Data = reader.result;

                console.log("Fetched image:", { url, mimeType, base64Data });
    
                if (!mimeType.startsWith('image/')) {
                    return reject(new Error('Unsupported image format'));
                }
    
                resolve({ base64Data, mimeType });
            };
    
            reader.onerror = reject;
            reader.readAsDataURL(blob); 
        });
    };    

    const handleLevelChange = (level) => {
        let updatedLevels = [...selectedLevels];
        if (selectedLevels.includes(level)) {
            updatedLevels = updatedLevels.filter(l => l !== level);
        } else {
            updatedLevels.push(level);
        }
        setSelectedLevels(updatedLevels);
        filterData(updatedLevels, selectedCategories);
    };

    const handleCategoryChange = (category) => {
        let updatedCategories = [...selectedCategories];
        if (selectedCategories.includes(category)) {
            updatedCategories = updatedCategories.filter(c => c !== category);
        } else {
            updatedCategories.push(category);
        }
        setSelectedCategories(updatedCategories);
        filterData(selectedLevels, updatedCategories);
    };

    const resetFilters = () => {
        setSelectedLevels(availableLevels);
        setSelectedCategories(availableCategories);
        setFilteredData(data);
        if (isCustomMode) {
            setCustomTableRows(data.map(row => ({ ...row, isIncluded: true })));
        }
    };
    

    const filterData = (levels, categories) => {
        if (isCustomMode) {
            const updatedRows = customTableRows.map(row => {
                const levelMatch = levels.length === 0 || levels.includes(row.Level);
                const categoryMatch = categories.length === 0 || categories.includes(row.Category);
                return {
                    ...row,
                    isIncluded: levelMatch && categoryMatch,
                };
            });
            setCustomTableRows(updatedRows);
        } else {
            const filtered = data.filter(row => {
                const levelMatch = levels.length === 0 || levels.includes(row.Level);
                const categoryMatch = categories.length === 0 || categories.includes(row.Category);
                return levelMatch && categoryMatch;
            });
            setFilteredData(filtered);
        }
    };

    const toggleFilterMenu = () => {
        setFilterMenuVisible((prev) => !prev);
    };

    if (loading) {
        return <LoadingScreen />;
    } 
    
    if (error){
        return (
            <div className="error-container">
                <p className="error-message">Hey something is not quite right, if this keeps happening please let Horizon View know</p>
                <button onClick={() => window.location.reload()}>Back To Login</button>
            </div>
        );
    }
    

    return (
        <div className="page-setup">
            
            <HeaderBar siteName={siteName} selectedDate={selectedDate} />
            <TopNavBar siteName={siteName} selectedDate={selectedDate}/>
        
            <div className="page-setup">
                <h1>Fault Data View</h1>
                <div className="page-header">

                    <div className= "fault-table-button-container">

                        {/* Filter Results Button with Dropdown Icon */}
                        <div className="filter-toggle">
                            <button className="filter-button" onClick={toggleFilterMenu}>
                                <FaFilter size={16} style={{ marginRight: '8px' }} />
                                Filter Results
                            </button>
                        </div>

                        <button
                            onClick={() => {
                                setIsCustomMode(!isCustomMode);
                                if (!isCustomMode) {
                                    setCustomTableRows(filteredData.map(row => ({ ...row, isIncluded: true })));
                                }
                            }}
                            className={`custom-mode-button ${isCustomMode ? 'custom-mode-active' : 'custom-mode-inactive'}`}
                            whileHover={{ scale: 1.05 }}
                            transition={{ duration: 0.3 }}
                        >
                            {isCustomMode ? (
                                <>
                                    <FaMinusCircle style={{ marginRight: '8px' }} />
                                    Exit Custom Mode
                                </>
                            ) : (
                                <>
                                    <FaPlusCircle style={{ marginRight: '8px' }} />
                                    Build Custom Table
                                </>
                            )}
                        </button>

                        {/* Export to CSV Button at the Top */}
                        <div className="export-button-section">
                            <button
                                className="export-button"
                                onClick={(event) => {
                                    console.log("Export button clicked");
                                    toggleExportMenu(event);
                                }}
                                aria-label="Open export options"
                            >
                                <FaDownload style={{ marginRight: '8px' }} />
                                Export
                            </button>

                            {exportMenuVisible && (
                                <ul className="export-options-dropdown">
                                    <li
                                        onClick={() => {
                                            setExportMenuVisible(false);
                                            exportToCSV();
                                        }}
                                        style={{
                                            padding: '8px',
                                            cursor: 'pointer',
                                            borderBottom: '1px solid #ddd',
                                        }}
                                        onMouseOver={(e) => e.target.style.backgroundColor = '#f0f0f0'}
                                        onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
                                    >
                                        Export as CSV
                                    </li>
                                    <li
                                        onClick={() => {
                                            setExportMenuVisible(false);
                                            exportToPDF(); // Call your PDF export function
                                        }}
                                        style={{
                                            padding: '8px',
                                            cursor: 'pointer',
                                        }}
                                        onMouseOver={(e) => e.target.style.backgroundColor = '#f0f0f0'}
                                        onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
                                    >
                                        Export as PDF
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>

                {/* Filter Menu for Levels and Categories */}
                <motion.div
                    className="fault-data-filter-menu"
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: filterMenuVisible ? "auto" : 0, opacity: filterMenuVisible ? 1 : 0 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                >
                    <div className= "filter-dropdown"> 
                        <div className="filter-section">
                            <h3>Filter by Levels</h3>
                            {availableLevels.map((level, index) => (
                                <div key={index}>
                                    <input
                                        type="checkbox"
                                        checked={selectedLevels.includes(level)}
                                        onChange={() => handleLevelChange(level)}
                                    />
                                    <label>{level}</label>
                                </div>
                            ))}
                        </div>

                        <div className="filter-section">
                            <h3>Filter by Categories</h3>
                            {availableCategories.map((category, index) => (
                                <div key={index}>
                                    <input
                                        type="checkbox"
                                        checked={selectedCategories.includes(category)}
                                        onChange={() => handleCategoryChange(category)}
                                    />
                                    <label>{category}</label>
                                </div>
                            ))}
                        </div>

                        <button
                            className="reset-button"
                            onClick={() => resetFilters()}
                        >
                            Reset
                        </button>
                    </div>
                    
                </motion.div>

                {/* Fault Data Table */}
                <DndProvider backend={HTML5Backend}>
                    <div
                        className={`table-container ${isTransitioning ? 'hidden' : ''} ${isCustomMode ? 'custom-mode' : ''}`}
                    >
                        <table>
                            <thead>
                                <tr>
                                    {columns.map((col, index) => (
                                        <DraggableHeader
                                            key={index}
                                            column={col}
                                            index={index}
                                            moveColumn={(dragIndex, hoverIndex) => {
                                                const updatedColumns = [...columns];
                                                const [removed] = updatedColumns.splice(dragIndex, 1);
                                                updatedColumns.splice(hoverIndex, 0, removed);
                                                setColumns(updatedColumns);
                                            }}
                                        />
                                    ))}
                                    {availableAttributes.filter(attr => !columns.includes(attr)).length > 0 && (
                                        <th>
                                            <div className="add-column-container">
                                                <FaPlusCircle
                                                    size={24}
                                                    className="add-column-icon"
                                                    onClick={toggleDropdown}
                                                />
                                                {showDropdown &&
                                                    ReactDOM.createPortal(
                                                        <ul className="dropdown-menu" style={{ position: 'absolute', top: dropdownPosition.y, left: dropdownPosition.x }}>
                                                            {availableAttributes.map((attribute, index) => (
                                                                <li key={index} onClick={() => addColumn(attribute)}>
                                                                    {attribute}
                                                                </li>
                                                            ))}
                                                        </ul>,
                                                        document.body
                                                    )
                                                }
                                            </div>
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {(isCustomMode ? customTableRows : filteredData).map((row, rowIndex) => (
                                    <tr
                                        key={rowIndex}
                                        onMouseEnter={(event) => handleRowHover(event, row.Location)}
                                        onMouseMove={handleMouseMoveThrottled}
                                        onMouseLeave={handleMouseLeave}
                                        className={
                                            isCustomMode 
                                                ? (row.isIncluded ? 'included-row' : 'excluded-row') 
                                                : ''
                                        }
                                    >
                                        {columns.map((col, colIndex) => (
                                            <td key={colIndex}>{row[col]}</td>
                                        ))}
                                        {isCustomMode && (
                                            <td className = "toggle-column">
                                                <button
                                                    onClick={() => {
                                                        const updatedRows = [...customTableRows];
                                                        updatedRows[rowIndex].isIncluded = !updatedRows[rowIndex].isIncluded;
                                                        setCustomTableRows(updatedRows);
                                                    }}
                                                    className={`toggle-include-button ${row.isIncluded ? 'included' : 'excluded'}`}
                                                >
                                                    {row.isIncluded ? (
                                                        <>
                                                            <FaCheckCircle style={{ marginRight: '5px' }} />
                                                            Included
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FaTimesCircle style={{ marginRight: '5px' }} />
                                                            Excluded
                                                        </>
                                                    )}
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </DndProvider>
            </div> 

            {tooltipVisible && tooltipContent && (
                <div
                    className="tooltip"
                    style={{
                        position: 'absolute',
                        top: tooltipPosition.y,
                        left: tooltipPosition.x,
                        background: 'white',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        padding: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        zIndex: 1000,
                    }}
                >
                    <img
                        src={tooltipContent}
                        alt="Fault Image"
                        style={{
                            maxWidth: '150px',
                            maxHeight: '150px',
                            display: 'block',
                            margin: '0 auto',
                        }}
                    />
                </div>
            )}

        </div>
    );
};

export default FaultData;
