import React from 'react';
import TopNavBar from './TopNavBar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import './SiteParameters.css';
import { FaUserCircle } from 'react-icons/fa';

const SiteParameters = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { siteName } = location.state || {};

  if (!siteName) {
    navigate('/'); 
}


  return (
    <div>
      <div className="header-bar">
      {/* Logo and Account Info Area */}
          <div className="logo-section">
          <img src="/HZVTLogo.png" alt="Site Logo" className="site-logo" />
          </div>

          <div className="account-icon-section"> 
          <button className="account-info-button" onClick={() => navigate('/account-info')}>
              <FaUserCircle size={32} className="account-icon" />
              <span>Account Info</span>
          </button>
          </div>

      </div>
      <TopNavBar siteName={siteName} />

      <h1>Site Parameters</h1>
      <p>This page will display specific information about the site.</p>
    </div>
  );
};

export default SiteParameters;
