import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './StatisticsView.css';

const StatisticsView = ({ isVisible, toggleVisibility, imagesByRectangleId }) => {
  const [aggregatedData, setAggregatedData] = useState({});
  const [totalAffectedPanels, setTotalAffectedPanels] = useState(0);
  const [kwPerPanel, setKwPerPanel] = useState(0.5); // Default value of kW per panel

  useEffect(() => {
    if (imagesByRectangleId && Object.keys(imagesByRectangleId).length > 0) {
      const levelData = {};

      Object.keys(imagesByRectangleId).forEach((rectangleId) => {
        const images = imagesByRectangleId[rectangleId] || [];

        images.forEach((item) => {
          const { level, category, effected_panels } = item;
          const levelKey = level || 'Uncategorized';
          if (!levelData[levelKey]) {
            levelData[levelKey] = {};
          }
          if (!levelData[levelKey][category]) {
            levelData[levelKey][category] = { category, affectedPanels: 0 };
          }
          levelData[levelKey][category].affectedPanels += effected_panels || 0;
        });
      });

      const totalPanels = Object.keys(levelData).reduce((levelTotal, level) => {
        return (
          levelTotal +
          Object.values(levelData[level]).reduce((categoryTotal, row) => categoryTotal + row.affectedPanels, 0)
        );
      }, 0);

      setAggregatedData(levelData);
      setTotalAffectedPanels(totalPanels);
    }
  }, [imagesByRectangleId]);

  // Function to return the class based on level
  const getLevelClass = (level) => {
    if (level === 'Level 1') return 'level-1';
    if (level === 'Level 2') return 'level-2';
    if (level === 'Level 3') return 'level-3';
    return ''; // Default class for other levels or uncategorized
  };

  const sortedLevels = Object.keys(aggregatedData).sort((a, b) => {
    const levelA = a.match(/\d+/);
    const levelB = b.match(/\d+/);
    return (levelA ? parseInt(levelA[0]) : 0) - (levelB ? parseInt(levelB[0]) : 0);
  });

  return (
    <div className="statistics-container">
      <div className={`statistics-view ${isVisible ? 'visible' : ''}`}>
        <div className="stat-data-table-container">
          <h2>Site Statistics</h2>
          {/* Input for kW per panel */}
          <div className="kw-input-container">
            <label>kW per panel:</label>
            <input
              type="number"
              value={kwPerPanel}
              onChange={(e) => setKwPerPanel(parseFloat(e.target.value) || 0)} // Update kwPerPanel when input changes
              step="0.1"
              min="0"
            />
          </div>
          <table className="stat-data-table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Affected Panels</th>
                <th>Impact kW</th>
              </tr>
            </thead>
            <tbody>
              {sortedLevels.map((level, levelIndex) => (
                <React.Fragment key={levelIndex}>
                  <tr className={getLevelClass(level)}>
                    <td><strong>{level}</strong></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {Object.values(aggregatedData[level]).map((row, categoryIndex) => (
                    <tr key={categoryIndex}>
                      <td>{row.category}</td>
                      <td>{row.affectedPanels}</td>
                      <td>{(row.affectedPanels * kwPerPanel).toFixed(2)}</td> {/* Impact kWh calculation */}
                    </tr>
                  ))}
                  <tr>
                    <td><strong>Totals for {level}</strong></td>
                    <td>
                      <strong>
                        {Object.values(aggregatedData[level]).reduce(
                          (total, row) => total + row.affectedPanels,
                          0
                        )}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {Object.values(aggregatedData[level]).reduce(
                          (total, row) => total + (row.affectedPanels * kwPerPanel),
                          0
                        ).toFixed(2)}
                      </strong>
                    </td>
                    <td>
                      
                    </td>
                  </tr>
                </React.Fragment>
              ))}

              {/* Overall Total */}
              <tr>
                <td><strong>Total Panels Affected</strong></td>
                <td><strong>{totalAffectedPanels}</strong></td>
                <td>
                  <strong>
                    {(
                      totalAffectedPanels * kwPerPanel
                    ).toFixed(2)}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="toggle-button" onClick={toggleVisibility}>
        {isVisible ? '▼' : '▲'}
      </div>
    </div>
  );
};

StatisticsView.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  imagesByRectangleId: PropTypes.object.isRequired,
};

export default StatisticsView;
