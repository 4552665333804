import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Signup from './components/Signup';
import SiteDashboard from './components/SiteDashboard';
import Home from './components/Home'; 
import Admin from './components/admin'; 
import AccountInfo from './components/accountInfo'; 
import FaultData from './components/FaultData'; 
import RevenueAnalysis from './components/RevenueAnalysis'; 
import SiteParameters from './components/SiteParameters'; 
import { auth } from './utils/firebase';

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Routes>
        {/* Admin Dashboard */}
        <Route
          path="/"
          element={currentUser ? <Admin currentUser={currentUser} /> : <Navigate to="/login" />}
        />

        {/* Site Dashboard */}
        <Route
          path="/site-dashboard/:siteName"
          element={currentUser ? <SiteDashboard currentUser={currentUser} /> : <Navigate to="/login" />}
        />

        {/*Account info */}
        <Route
          path="/account-info"
          element={currentUser ? <AccountInfo currentUser={currentUser} /> : <Navigate to="/login" />}
        />

        {/* Other routes */}
        <Route path="/fault-data" element={currentUser ? <FaultData /> : <Navigate to="/login" />} />
        <Route path="/revenue-analysis" element={currentUser ? <RevenueAnalysis /> : <Navigate to="/login" />} />
        <Route path="/site-parameters" element={currentUser ? <SiteParameters /> : <Navigate to="/login" />} />

        {/* route for the home/mapview */}
        <Route
          path="/home/:siteName"
          element={currentUser ? <Home currentUser={currentUser} /> : <Navigate to="/login" />}
        />

        {/* Authentication Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
